<template>
  <div>
    <v-row class="mt-5" justify="center">
      <p>Enter your email to reset your password.</p>
    </v-row>
    <v-form ref="form" class="my-5 mx-3 font-weight-light">
      <v-text-field
        name="email"
        prepend-icon="mdi-email"
        type="text"
        v-model.lazy="email"
        :rules="rules.email"
        autocomplete="off"
      />
    </v-form>
    <v-row class="px-5 my-5">
      <v-btn
        dark
        class="font-weight-medium propercase"
        color="primary"
        @click="onSend"
        block
      >
        Send
      </v-btn>
    </v-row>
    <v-row class="mx-3">
      <p>
        Already have an account?
        <span
          class="pl-2 font-weight-regular moduleLink"
          @click="gotoLink('signin')"
          >Sign In!</span
        >
      </p>
    </v-row>
  </div>
</template>
<script>
export default {
  data() {
    return {
      email: null,
      rules: {
        email: [
          (v) => !!v || "Email is required",
          (v) => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return pattern.test(v) || "Email is invalid";
          },
        ],
      },
    };
  },
  methods: {
    gotoLink(link) {
      this.$router.push({ name: link });
    },
    async onSend() {
      if (!this.$refs.form.validate()) {
        return;
      }

      try {
        const response = await this.$store.dispatch("auth/resetPassword", {
          email: this.email,
        });
        this.$store.commit("ui/SNACK_BAR", response);
        this.$router.replace("/");
      } catch (err) {
        this.$store.commit(
          "ui/SNACK_BAR",
          err.message || "Failed to reset password, try again later."
        );
      }
    },
  },
};
</script>
<style scoped></style>
